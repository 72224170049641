<template>
  <div>
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#68D389" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left align-self-center>{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center>
      <v-flex xs12 md12>
        <v-img
          v-if="program.coverImage"
          :src="mediaURL + program.coverImage"
          width="100%"
          contain
        >
          <v-layout wrap justify-center fill-height hidden-xs-only>
            <v-flex xs4 align-self-end>
              <v-card tile color="#000000" elevation="5" dark width="350px">
                <v-layout wrap justify-center>
                  <v-flex xs12 pa-6 text-center>
                    <v-badge
                      icon="mdi-check-circle-outline"
                      overlap
                      :color="badgeColor"
                    >
                      <template v-if="program.started" slot="badge">
                        <span style="font-family: poppinsRegular">STARTED</span>
                      </template>
                      <template v-else slot="badge"
                        ><span style="font-family: poppinsRegular"
                          >STOPPED
                        </span>
                      </template>
                      <span style=" 	font- family: poppinsbold; font-size: 28px">
                        {{ program.progName }}
                      </span>
                    </v-badge>
                  </v-flex>
                  <v-flex xs12>
                    <v-progress-linear
                      value="100"
                      color="#FFE200"
                    ></v-progress-linear>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
            <v-flex xs12 sm6 md3 align-self-end text-right>
              <router-link :to="'/editProgram/' + program._id">
                <v-btn
                  color="#68D389"
                  small
                  class="px-3"
                  :ripple="false"
                  depressed
                >
                  <span
                    style="
                      font-size: 12px;
                      color: #ffffff;
                      font-family: poppinsregular;
                    "
                  >
                    Edit Program
                  </span>
                </v-btn>
              </router-link>
            </v-flex>
            <v-flex xs12 sm6 md3 align-self-end text-right>
              <v-btn
                @click="changeStatus"
                :color="btnColor"
                small
                class="px-3"
                :ripple="false"
                depressed
              >
                <span
                  v-if="program.started"
                  style="
                    font-size: 12px;
                    color: #ffffff;
                    font-family: poppinsregular;
                  "
                  >Stop Program</span
                >
                <span
                  v-else
                  style="
                    font-size: 12px;
                    color: #ffffff;
                    font-family: poppinsregular;
                  "
                  >start program</span
                >
              </v-btn>
            </v-flex>
            <!-- <v-flex xs1 align-self-end>
              <v-icon size="35" style="color: white;">mdi-image-edit-outline</v-icon>
            </v-flex> -->
          </v-layout>
        </v-img>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center hidden-sm-and-up>
      <v-flex xs6 sm6 md6 align-self-end text-right pa-4>
        <router-link :to="'/editProgram/' + program._id">
          <v-btn
            color="#68D389"
            small
            block
            class="px-3"
            :ripple="false"
            depressed
          >
            <span
              style="
                font-size: 12px;
                color: #ffffff;
                font-family: poppinsregular;
              "
            >
              Edit Program
            </span>
          </v-btn>
        </router-link>
      </v-flex>
      <v-flex xs6 sm6 md6 align-self-end text-right pa-4>
        <v-btn
          @click="changeStatus"
          :color="btnColor"
          small
          block
          class="px-3"
          :ripple="false"
          depressed
        >
          <span
            v-if="program.started"
            style="font-size: 12px; color: #ffffff; font-family: poppinsregular"
            >Stop Program</span
          >
          <span
            v-else
            style="font-size: 12px; color: #ffffff; font-family: poppinsregular"
            >start program</span
          >
        </v-btn>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center pt-8>
      <v-flex xs12>
        <v-layout wrap justify-center>
          <v-flex xs6>
            <v-layout wrap>
              <v-flex xs12 sm6 md3>
                <v-layout wrap>
                  <v-flex xs12 align-self-center>
                    <span style="font-family: poppinsmedium">
                      Start Point
                    </span>
                  </v-flex>
                  <v-flex xs12>
                    <span style="font-family: poppinsmedium; color: #9b9090">{{
                      program.startPoint
                    }}</span>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12 sm6 md3>
                <v-layout wrap>
                  <v-flex xs12 align-self-center>
                    <span style="font-family: poppinsmedium"> End Point </span>
                  </v-flex>
                  <v-flex xs12>
                    <span style="font-family: poppinsmedium; color: #9b9090">
                      {{ program.endPoint }}</span
                    >
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12 sm6 md3>
                <v-layout wrap>
                  <v-flex xs12 align-self-center>
                    <span style="font-family: poppinsmedium"> Min Guest </span>
                  </v-flex>
                  <v-flex xs12>
                    <span style="font-family: poppinsmedium; color: #9b9090">
                      {{ program.minGuest }}</span
                    >
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12 sm6 md3>
                <v-layout wrap>
                  <v-flex xs12 align-self-center>
                    <span style="font-family: poppinsmedium"> Max Guest </span>
                  </v-flex>
                  <v-flex xs12>
                    <span style="font-family: poppinsmedium; color: #9b9090">
                      {{ program.maxGuest }}</span
                    >
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs6>
            <v-layout wrap>
              <v-flex xs12 sm6 md3>
                <v-layout wrap>
                  <v-flex xs12 align-self-center>
                    <span style="font-family: poppinsmedium"> Min Age </span>
                  </v-flex>
                  <v-flex xs12>
                    <span style="font-family: poppinsmedium; color: #9b9090">
                      {{ program.minAge }}</span
                    >
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12 sm6 md3>
                <v-layout wrap>
                  <v-flex xs12 align-self-center>
                    <span style="font-family: poppinsmedium"> Max Age </span>
                  </v-flex>
                  <v-flex xs12>
                    <span style="font-family: poppinsmedium; color: #9b9090">
                      {{ program.maxAge }}</span
                    >
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12 sm6 md3>
                <v-layout wrap>
                  <v-flex xs12 align-self-center>
                    <span style="font-family: poppinsmedium"> Duration </span>
                  </v-flex>
                  <v-flex xs12>
                    <span style="font-family: poppinsmedium; color: #9b9090">
                      {{ program.duration }}hr</span
                    >
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12 sm6 md3>
                <v-layout wrap>
                  <v-flex xs12 align-self-center>
                    <span style="font-family: poppinsmedium">
                      Reporting Time
                    </span>
                  </v-flex>
                  <v-flex xs12>
                    <span style="font-family: poppinsmedium; color: #9b9090">
                      {{ program.reportingTime }}</span
                    >
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center py-4 px-2>
      <v-flex xs12 lg11 xl11>
        <v-layout wrap justify-center>
          <v-flex xs12 sm12 md8 lg8 xl8>
            <v-layout wrap justify-center>
              <v-flex xs12 pt-3>
                <span
                  style="color: #9b9090; text-align: left"
                  v-html="program.description"
                >
                </span>
              </v-flex>
              <v-flex xs12 pt-3 v-if="program.notes" text-left>
                <span style="font-size: 16px; font-family: poppinssemibold">
                  Notes
                </span>

                <span
                  style="color: #9b9090; text-align: left"
                  v-html="program.notes"
                >
                </span>
              </v-flex>
              <v-flex xs12 pt-3 v-if="program.rules" text-left>
                <span style="font-size: 16px; font-family: poppinssemibold">
                  Rules
                </span>
                <span>
                  <span
                    style="color: #9b9090; text-align: left"
                    v-html="program.rules"
                  >
                  </span>
                </span>
              </v-flex>
            </v-layout>
          </v-flex>
           <v-flex xs12 sm6 md4 lg4 xl4 text-center>
            <v-layout wrap justify-center>
              <v-flex xs12 v-if="program.onlinePercent > 0">
                <router-link
                  :to="
                    '/Admin/ReservationIB/Availability?type=' +
                    program._id +
                       '&name=' +
                    program.progName +
                    '&caption=' +
                    program.caption
                  "
                >
                  <v-btn color="#68D389" large :ripple="false" depressed>
                    <v-layout wrap justify-center>
                      <v-flex xs8 align-self-center>
                        <span
                          style="
                            font-family: poppinssemibold;
                            font-size: 16px;
                            color: #ffffff;
                          "
                        >
                          Add Reservation
                        </span>
                      </v-flex>
                      <v-flex xs4 align-self-center>
                        <v-img
                          src="./../../../../assets/iconsets/arrowRight.png"
                          height="15px"
                          contain
                        />
                      </v-flex>
                    </v-layout>
                  </v-btn>
                </router-link>
              </v-flex>
              <v-flex xs12 v-if="program.onlinePercent < 1">
                <span class="tableHeading">
                  This Programme is not available for online Booking
                </span>
              </v-flex>
               <v-flex xs12 py-4 v-if="program.photos">
                <v-layout wrap justify-center v-if="program.photos.length > 0">
                  <viewer :images="program.photos" :key="program.photos.length">
                    <span
                      v-for="item in program.photos"
                      :key="item"
                      class="pa-2"
                    >
                      <img
                        :src="mediaURL + item"
                        height="150px"
                        width="150px"
                      />
                    </span>
                  </viewer>
                </v-layout>
              </v-flex>
            </v-layout>
            
           </v-flex>
        </v-layout>
        <v-layout wrap justify-center py-4 px-2>
          <v-flex> </v-flex>
        </v-layout>
    
        <v-layout wrap justify-start pt-4>
          <v-flex
            xs6
            text-left
            class="text-capitalize"
            style="font-family: poppinssemibold; font-size: 16px"
          >
            Recent Package Rates 
          </v-flex>
          <v-flex xs3 text-right>
            <span v-if="packageView" class="pr-4">
              <router-link :to="'/packageRates/' + program._id">
                <v-btn
                  outlined
                  tile
                  color="#68d389"
                  light
                  depressed
                  class="itemValue px-2 py-0"
                >
                  <span style="color: #68d389">View More</span>
                </v-btn>
              </router-link>
            </span>
          </v-flex>
          <v-flex xs3 text-right>
            <span v-if="pricing.length > 0">
              <router-link :to="'/addPackage/' + program._id + '/' + isAddMore">
                <v-btn
                  outlined
                  tile
                  color="#68d389"
                  light
                  depressed
                  class="itemValue px-2 py-0"
                >
                  <span style="color: #68d389">Add More</span>
                </v-btn>
              </router-link>
            </span>
          </v-flex>
          <span v-if="pricing.length > 0">
            <v-flex
              xs12
              hidden-sm-and-down
              py-4
              v-for="(item, i) in pricing"
              :key="i"
            >
              <v-card height="60px" color="#68D389">
                <v-layout wrap justify-start py-5>
                  <v-flex md3>
                    <h4 style="color: #ffffff; font-family: poppinssemibold">
                      Package Rate
                      <span>
                        <router-link
                          :to="'/editPackage/' + item._id + '/' + program._id"
                        >
                          <v-icon
                            color="#ffffff"
                            style="font-size: 22px; font-family: poppinsbold"
                          >
                            mdi-circle-edit-outline
                          </v-icon>
                        </router-link>
                      </span>
                    </h4>
                  </v-flex>
                  <v-flex md8 text-right>
                    <span style="color: #ffffff; font-family: poppinssemibold">
                      {{ item.fromDate.slice(0, 15) }} to
                      {{ item.toDate.slice(0, 15) }}</span
                    >
                  </v-flex>
                  <v-flex xs1>
                    <v-dialog v-model="item.delete" max-width="600px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon color="#ffffff" v-on="on" v-bind="attrs"
                          >mdi-delete</v-icon
                        >
                      </template>
                      <v-card>
                        <v-card-title
                          >Are you sure you want to delete this
                          Package?</v-card-title
                        >
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="item.delete = false"
                            >Cancel</v-btn
                          >
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="deletePackage(item)"
                            >OK</v-btn
                          >
                          <v-spacer></v-spacer>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-flex>
                </v-layout>
              </v-card>

              <v-card outlined>
                <v-layout justify-center py-3>
                  <v-flex xs3 align-self-center>
                    <span style="font-family: poppinsRegular"
                      >Package Rate</span
                    >
                  </v-flex>
                  <v-flex text-left>
                    <span style="font-family: poppinsRegular">Indian</span>
                  </v-flex>
                  <v-flex text-left>
                    <span style="font-family: poppinsRegular">Foreigner</span>
                  </v-flex>
                  <v-flex text-left>
                    <span style="font-family: poppinsRegular">Children</span>
                  </v-flex>
                </v-layout>
              </v-card>
              <v-layout wrap justify-center pt-2>
                <v-flex xs12>
                  <v-card outlined v-if="item">
                    <v-layout wrap justify-start py-4 px-4>
                      <v-flex xs3>
                        <span
                          style="font-family: poppinsmedium; color: #9b9090"
                        >
                          Week Days
                        </span>
                      </v-flex>
                      <v-flex text-left>
                        <span style="font-family: poppinsregular">
                          {{ item.indian }} INR
                        </span>
                      </v-flex>
                      <v-flex text-left>
                        <span style="font-family: poppinsregular">
                          {{ item.foreigner }} INR
                        </span>
                      </v-flex>
                      <v-flex text-left>
                        <span style="font-family: poppinsregular">
                          {{ item.children }} INR
                        </span>
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-flex>
                <v-flex xs12 pt-1>
                  <v-card outlined v-if="item.holidays">
                    <v-layout wrap justify-start py-4 px-4>
                      <v-flex xs3>
                        <span
                          style="font-family: poppinsmedium; color: #9b9090"
                        >
                          Weekends And Holidays
                        </span>
                      </v-flex>
                      <v-flex text-left>
                        <span style="font-family: poppinsregular">
                          {{ item.holidays.indian }} INR
                        </span>
                      </v-flex>
                      <v-flex text-left>
                        <span style="font-family: poppinsregular">
                          {{ item.holidays.foreigner }} INR
                        </span>
                      </v-flex>
                      <v-flex text-left>
                        <span style="font-family: poppinsregular">
                          {{ item.holidays.children }} INR
                        </span>
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-flex>
                <v-flex xs12 pt-1>
                  <v-card outlined v-if="item.isExtraPerHeadAvailable">
                    <v-layout wrap justify-start py-4 px-4>
                      <v-flex xs3>
                        <span
                          style="font-family: poppinsmedium; color: #9b9090"
                        >
                          Extra Per head
                        </span>
                      </v-flex>
                      <v-flex text-left>
                        <span style="font-family: poppinsregular">
                          {{ item.extraperhead.indian }} INR
                        </span>
                      </v-flex>
                      <v-flex text-left>
                        <span style="font-family: poppinsregular">
                          {{ item.extraperhead.foreigner }} INR
                        </span>
                      </v-flex>
                      <v-flex text-left>
                        <span style="font-family: poppinsregular">
                          {{ item.extraperhead.children }} INR
                        </span>
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-flex>
                <v-layout wrap justify-start pt-4>
                  <v-flex xs12 text-left v-if="item.packageFacility.length > 0">
                    <span style="font-family: poppinsregular"
                      >Package Facility</span
                    >
                  </v-flex>
                  <v-flex xs12>
                    <v-layout wrap justify-start>
                      <v-flex pa-2 v-for="pf in item.packageFacility" :key="pf">
                        <v-card flat color="#F2F2F2">
                          <v-layout wrap justify-center>
                            <v-flex xs12 pa-2 px-4 text-center>
                              <span style="font-family: poppinsregular">{{
                                pf
                              }}</span>
                            </v-flex>
                          </v-layout>
                        </v-card>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-layout>
            </v-flex>
          </span>
          <span v-else class="emptyField px-2 py-0 pb-5 pt-5">
            No Package Rate Available....
            <router-link :to="'/addPackage/' + program._id + '/' + isAddMore">
              <v-btn
                outlined
                tile
                color="#68d389"
                light
                depressed
                class="itemValue px-2 py-0"
              >
                <span style="color: #68d389">Add</span>
              </v-btn>
            </router-link>
          </span>
        </v-layout>
        <v-layout wrap justify-center pt-4 v-if="pricing.length > 0">
          <v-flex xs12 hidden-md-and-up v-for="(item, i) in pricing" :key="i">
            <v-card auto color="#68D389">
              <v-layout wrap justify-center py-5>
                <v-flex xs12 md3>
                  <h4 style="color: #ffffff; font-family: poppinssemibold">
                    Package Rate
                    <span>
                      <router-link :to="'/editPackage/' + item._id + '/' + program._id">
                        <v-icon
                          color="#ffffff"
                          style="font-size: 22px; font-family: poppinsbold"
                        >
                          mdi-circle-edit-outline
                        </v-icon>
                      </router-link>
                    </span>
                  </h4>
                </v-flex>
                <v-flex xs7 md8 text-right>
                  <span style="color: #ffffff; font-family: poppinssemibold">
                    {{ item.fromDate.slice(0, 15) }} to
                    {{ item.toDate.slice(0, 15) }}</span
                  >
                </v-flex>
                <v-flex xs5>
                  <v-dialog v-model="item.delete" max-width="600px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="#ffffff" v-on="on" v-bind="attrs"
                        >mdi-delete</v-icon
                      >
                    </template>
                    <v-card>
                      <v-card-title
                        >Are you sure you want to delete this
                        Package?</v-card-title
                      >
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="item.delete = false"
                          >Cancel</v-btn
                        >
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="deletePackage(item)"
                          >OK</v-btn
                        >
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-flex>
              </v-layout>
            </v-card>
            <v-layout wrap justify-center pt-2>
              <!-- <v-flex xs6 align-self-center class="pb-2">
                <span class="tableHeading">Package Rate</span>
              </v-flex> -->
              <v-flex xs12>
                <v-flex xs12>
                  <span style="font-family: poppinsmedium">Indian</span>
                </v-flex>
                <v-card outlined>
                  <v-layout wrap justify-start py-4 px-4>
                    <v-flex xs6>
                      <span style="font-family: poppinsmedium; color: #9b9090">
                        Week Days
                      </span>
                    </v-flex>
                    <v-flex xs6>
                      <span class="tableHeading" style="color: #606060">
                        {{ item.indian }} INR
                      </span>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
              <!-- <v-flex xs12 pt-1>
                <v-card outlined>
                  <v-layout wrap justify-start py-4 px-4>
                    <v-flex xs6>
                      <span style="font-family: poppinsmedium; color: #9b9090">
                        Weekends
                      </span>
                    </v-flex>
                    <v-flex xs6>
                      <span class="tableHeading" style="color: #606060">
                        {{ item.weekend.indian }} INR
                      </span>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex> -->
              <v-flex xs12 pt-1>
                <v-card outlined>
                  <v-layout wrap justify-start py-4 px-4>
                    <v-flex xs6>
                      <span style="font-family: poppinsmedium; color: #9b9090">
                        Weekends And Holidays
                      </span>
                    </v-flex>
                    <v-flex xs6>
                      <span class="tableHeading" style="color: #606060">
                        {{ item.holidays.indian }} INR
                      </span>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
              <v-flex xs12 pt-1>
                <v-card outlined>
                  <v-layout wrap justify-start py-4 px-4>
                    <v-flex xs6>
                      <span style="font-family: poppinsmedium; color: #9b9090">
                        Extra Per head
                      </span>
                    </v-flex>
                    <v-flex xs6>
                      <span class="tableHeading" style="color: #606060">
                        {{ item.extraperhead.indian }} INR
                      </span>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-center pt-2>
              <v-flex xs12>
                <v-flex xs12>
                  <span style="font-family: poppinsmedium">Foreigner</span>
                </v-flex>
                <v-card outlined>
                  <v-layout wrap justify-start py-4 px-4>
                    <v-flex xs6>
                      <span style="font-family: poppinsmedium; color: #9b9090">
                        Week Days
                      </span>
                    </v-flex>
                    <v-flex xs6>
                      <span class="tableHeading" style="color: #606060">
                        {{ item.foreigner }} INR
                      </span>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
              <v-flex xs12 pt-1>
                <v-card outlined>
                  <v-layout wrap justify-start py-4 px-4>
                    <v-flex xs6>
                      <span style="font-family: poppinsmedium; color: #9b9090">
                        Weekends And Holidays
                      </span>
                    </v-flex>
                    <v-flex xs6>
                      <span class="tableHeading" style="color: #606060">
                        {{ item.holidays.foreigner }} INR
                      </span>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
              <v-flex xs12 pt-1>
                <v-card outlined>
                  <v-layout wrap justify-start py-4 px-4>
                    <v-flex xs6>
                      <span style="font-family: poppinsmedium; color: #9b9090">
                        Extra Per head
                      </span>
                    </v-flex>
                    <v-flex xs6>
                      <span class="tableHeading" style="color: #606060">
                        {{ item.extraperhead.foreigner }} INR
                      </span>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-center pt-2>
              <v-flex xs12>
                <v-flex xs12>
                  <span style="font-family: poppinsmedium">Children</span>
                </v-flex>
                <v-card outlined>
                  <v-layout wrap justify-start py-4 px-4>
                    <v-flex xs6>
                      <span style="font-family: poppinsmedium; color: #9b9090">
                        Week Days
                      </span>
                    </v-flex>
                    <v-flex xs6>
                      <span class="tableHeading" style="color: #606060">
                        {{ item.children }} INR
                      </span>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
              <v-flex xs12 pt-1>
                <v-card outlined>
                  <v-layout wrap justify-start py-4 px-4>
                    <v-flex xs6>
                      <span style="font-family: poppinsmedium; color: #9b9090">
                        Weekends And Holidays
                      </span>
                    </v-flex>
                    <v-flex xs6>
                      <span class="tableHeading" style="color: #606060">
                        {{ item.holidays.children }} INR
                      </span>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
              <v-flex xs12 pt-1>
                <v-card outlined>
                  <v-layout wrap justify-start py-4 px-4>
                    <v-flex xs6>
                      <span style="font-family: poppinsmedium; color: #9b9090">
                        Extra Per head
                      </span>
                    </v-flex>
                    <v-flex xs6>
                      <span class="tableHeading" style="color: #606060">
                        {{ item.extraperhead.children }} INR
                      </span>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>

              <v-layout wrap justify-start pt-4>
                <v-flex xs12 text-left>
                  <span style="font-family: poppinsregular"
                    >Package Facility</span
                  >
                </v-flex>
                <v-flex xs12>
                  <v-layout wrap justify-start>
                    <v-flex pa-2 v-for="pf in item.packageFacility" :key="pf">
                      <v-card flat color="#F2F2F2">
                        <v-layout wrap justify-center>
                          <v-flex xs12 pa-2 px-4 text-center>
                            <span style="font-family: poppinsregular">{{
                              pf
                            }}</span>
                          </v-flex>
                        </v-layout>
                      </v-card>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-layout wrap>
          <v-flex
            md6
            text-left
            style="font-family: poppinssemibold; font-size: 16px"
          >
            Recent Slots
          </v-flex>
          <v-flex xs3 text-right>
            <span v-if="slotView" class="pr-4">
              <router-link :to="'/slots/' + program._id">
                <v-btn
                  outlined
                  tile
                  color="#68d389"
                  light
                  depressed
                  class="itemValue px-2 py-0"
                >
                  <span style="color: #68d389">View More</span>
                </v-btn>
              </router-link>
            </span>
          </v-flex>
          <v-flex xs3 text-right>
            <span v-if="slots.length > 0">
              <router-link :to="'/addSlot/' + program._id">
                <v-btn
                  outlined
                  tile
                  color="#68d389"
                  light
                  depressed
                  class="itemValue px-2 py-0"
                >
                  <span style="color: #68d389">Add More</span>
                </v-btn>
              </router-link>
            </span>
          </v-flex>
        </v-layout>
        <v-layout wrap justify-start v-if="slots.length > 0">
          <v-flex md6 xs12 pa-3 py-3 v-for="item in slots" :key="item._id">
            <v-layout wrap justify-center>
              <v-flex md12 align-self-center>
                <v-card auto color="#68D389">
                  <v-layout wrap justify-center py-4>
                    <v-flex xs3 md3 align-self-center>
                      <h4 style="color: #ffffff; font-family: poppinssemibold">
                        <span>
                          <router-link :to="'/editSlot/' + item._id + '/' + id">
                            <v-icon
                              color="#ffffff"
                              style="font-size: 22px; font-family: poppinsbold"
                            >
                              mdi-circle-edit-outline
                            </v-icon>
                          </router-link>
                        </span>
                      </h4>
                    </v-flex>
                    <v-flex xs6 md8 text-right>
                      <span
                        style="color: #ffffff; font-family: poppinssemibold"
                      >
                        {{ item.fromDate.slice(0, 15) }} to
                        {{ item.toDate.slice(0, 15) }}</span
                      >
                    </v-flex>
                    <v-flex xs3 md1>
                      <v-dialog v-model="item.delete" max-width="600px">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon color="#ffffff" v-on="on" v-bind="attrs"
                            >mdi-delete</v-icon
                          >
                        </template>
                        <v-card>
                          <v-card-title
                            >Are you sure you want to delete this
                            Slot?</v-card-title
                          >
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="item.delete = false"
                              >Cancel</v-btn
                            >
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="deleteSlot(item)"
                              >OK</v-btn
                            >
                            <v-spacer></v-spacer>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-flex>
                  </v-layout>
                </v-card>
                <!-- <v-card outlined>
                  <v-layout py-1 wrap justify-center>
                    <v-flex md4 align-self-center>
                      <span> Start TIme </span>
                    </v-flex>
                    <v-flex md4 align-self-center>
                      <span> End TIme </span>
                    </v-flex>
                    <v-flex md4 align-self-center>
                      <span> Available No. </span>
                    </v-flex>
                  </v-layout>
                </v-card> -->
              </v-flex>
              <v-flex md12 xs12 align-self-center>
                <v-card>
                  <v-layout
                    py-2
                    v-for="(subitem, index) in item.slotDetails"
                    :key="index"
                    wrap
                    justify-center
                  >
                    <v-flex md6 align-self-center>
                      <span style="font-family: poppinsRegular">
                        {{ formatTime(subitem.startTime) }} -
                        {{ formatTime(subitem.endTime) }}
                      </span>
                    </v-flex>
                    <v-flex md6 align-self-center v-if="program.isCottage">
                      <span style="font-family: poppinsRegular">
                        {{ subitem.availableNo }} Rooms Available</span
                      >
                    </v-flex>
                    <v-flex md6 align-self-center v-else>
                      <span style="font-family: poppinsRegular">
                        {{ subitem.availableNo }} Seats Available</span
                      >
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-layout v-else wrap pt-5>
          <span class="emptyField px-2 py-0">No Slot Available...</span>
          <router-link :to="'/addSlot/' + program._id">
            <v-btn
              outlined
              tile
              color="#68d389"
              light
              depressed
              class="itemValue px-2 py-0"
            >
              <span style="color: #68d389">Add</span>
            </v-btn>
          </router-link>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  props: ["id"],
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      program: {},
      pricing: [],
      packageFacility: [],
      isAddMore: true,
      slots: [],
      packageView: false,
      slotView: false,
      badgeColor: "error",
      btnColor: "success",
    };
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    changeStatus() {
      this.appLoading = true;
      var data = {};
      data["program"] = this.id;
      axios({
        method: "POST",
        url: "/program/status",
        data: data,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          // if (response.data.package) this.pricing = response.data.package;
          if (response.data.status) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.getData();
            this.appLoading = false;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/programme/adminget",
        params: {
          id: this.id,
        },
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          // if (response.data.package) this.pricing = response.data.package;
          if (response.data.status) {
            this.program = response.data.data;
            if (response.data.package != null)
              this.packageFacility = response.data.package;
            if (this.program.started) {
              this.badgeColor = "success";
              this.btnColor = "error";
            } else {
              this.btnColor = "primary";
              this.badgeColor = "error";
            }
            var tempPackage = [];
            var tempSlot = [];
            tempPackage = response.data.package;
            tempSlot = response.data.slots;
            if (tempPackage.length > 2) {
              this.packageView = true;
              this.pricing = tempPackage.slice(0, 2);
            } else {
              this.pricing = response.data.package;
            }
            if (tempSlot.length > 2) {
              this.slots = tempSlot.slice(0, 2);
              this.slotView = true;
            } else {
              this.slots = response.data.slots;
            }
            for (var i = 0; i < this.pricing.length; i++) {
              var fromDate = new Date(this.pricing[i].fromDate).toString();
              var toDate = new Date(this.pricing[i].toDate).toString();
              this.pricing[i].fromDate = fromDate;
              this.pricing[i].toDate = toDate;
            }
            for (var j = 0; j < this.slots.length; j++) {
              var fromDate1 = new Date(this.slots[j].fromDate).toString();
              var toDate1 = new Date(this.slots[j].toDate).toString();
              this.slots[j].fromDate = fromDate1;
              this.slots[j].toDate = toDate1;
            }
            this.appLoading = false;
          } else {
            this.msg = response.data.msg;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    deletePackage(r) {
      var data = {};
      data["id"] = r._id;
      axios({
        url: "/program/rate/remove",
        method: "POST",
        data: data,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.delete = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Delete Sucessfully";
            this.showSnackBar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    deleteSlot(r) {
      var data = {};
      data["id"] = r._id;
      axios({
        url: "/programme/slot/remove",
        method: "POST",
        data: data,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.delete = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Delete Sucessfully";
            this.showSnackBar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    formatTime(item) {
      if (!item) return;
      var hours = Number(item.split(":")[0]);
      var minutes = Number(item.split(":")[1]);
      var ampm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes + " " + ampm;
      return strTime;
    },
    info(id) {
      this.$router.push("/packageRates/" + id);
    },
  },
};
</script>